import moment from 'moment';
import { cloneDeep } from 'lodash';
import {
  BookingPaymentData,
  BookingPersonalData,
  CalculatedPricesType,
  FormConfigData,
  SelectedAddons,
  SelectedInsurances
} from 'src/booking-engine/types';
import { CarOffer } from 'src/cars/types';
import { dayDiff } from 'src/utils/date-time';
import { SearchData } from 'types/search/search-data.type';
import ExchangeOffice from 'components/common/exchange-office.class';
import { CurrencyRate } from 'types/currency-rate.type';
import { getLocationById } from 'src/page-params-initializer/subpage-data';
import { resolve } from 'path';
import { getAddonPrice } from '../../modules/booking-form/tools';

export const defaultCustomerData: BookingPersonalData = {
  title: '',
  gender: '',
  dob: null,
  fname: '',
  sname: '',
  street: '',
  houseno: '',
  postcode: '',
  city: '',
  state: '',
  country: '',
  phone: '',
  email: '',
  fax: '',
  company: ''
};

export const getDefaultCustomerData = (cor?: string): BookingPersonalData => {
  const data = cloneDeep(defaultCustomerData);
  if (cor) data.country = cor;
  return data;
};

export const defaultPaymentData: BookingPaymentData = {
  usedPayment: 'CC',
  creditCard: {
    name: '',
    cvc: '',
    type: '',
    number: '',
    numberObfuscated: '',
    expire: null,
    holder: ''
  }
};

export const defaultBookingPricing = null;

export const rentDays = (search: SearchData) =>
  dayDiff(moment(search.dates.from).hour(0).minute(0), moment(search.dates.to).hour(0).minute(0));

const getEuTravelPolicy = (locale, url = '', addLocale = false) => {
  if (url) {
    if (addLocale) {
      return `${url}&lang=${locale}`;
    }
    return url;
  }
  return '';
};

export const prepareTerms = (termsNode, locale) => {
  if (termsNode) {
    if (termsNode.euTravelPolicyUrl && termsNode.euTravelPolicyAddLocaleParam) {
      termsNode.euTravelPolicyUrl = getEuTravelPolicy(
        locale,
        termsNode.euTravelPolicyUrl,
        termsNode.euTravelPolicyAddLocaleParam
      );
    }
    return termsNode;
  }
  return {};
};
export const calcTotalPrice = (
  offer: CarOffer,
  addons: Array<SelectedAddons>,
  insurances: Array<SelectedInsurances>,
  rentalDays: number,
  exchangeRates?: CurrencyRate
): CalculatedPricesType => {
  if (!offer) return null;
  const ExOffice = new ExchangeOffice(exchangeRates || {});
  let sum = offer && offer.prepaid !== false ? offer.price : 0;
  let sumAtPickup = offer && offer.prepaid === false ? offer.price : 0;
  const prepayable = addons.filter((addon) => !addon.included && addon.prepayable);
  const postpayable = addons.filter((addon) => !addon.included && !addon.prepayable);

  const chargesPostpayable =
    offer && offer.charges && offer.charges.filter((fee) => !fee.included && fee.required);

  chargesPostpayable.forEach((fee) => {
    let { price } = fee;
    if (fee.price && offer.currency !== fee.currency) {
      price = ExOffice.getPriceInAgentCurrency(fee.price, fee.currency);
    }
    sumAtPickup += price;
  });

  prepayable.forEach((addon) => {
    let price = getAddonPrice(addon, rentalDays);
    if (price && offer.currency !== addon.currency) {
      price = ExOffice.getPriceInAgentCurrency(price, addon.currency);
    }
    sum += price;
  });

  postpayable.forEach((addon) => {
    let price = getAddonPrice(addon, rentalDays);
    if (price && offer.currency !== addon.currency) {
      price = ExOffice.getPriceInAgentCurrency(price, addon.currency);
    }
    sumAtPickup += price;
  });

  insurances.forEach((insurance) => {
    if (offer && offer.prepaid !== false) {
      sum += insurance.price;
    }
    if (offer && offer.prepaid === false) {
      sumAtPickup += insurance.price;
    }
  });

  return {
    total: sum + sumAtPickup,
    totalPrePaid: sum,
    totalPostPaid: sumAtPickup,
    totalPerDay: (sum + sumAtPickup) / rentalDays,
    totalInAgentCurrency: ExOffice.getPriceInAgentCurrency(sum + sumAtPickup, offer.currency),
    totalPerDayInAgentCurrency: ExOffice.getPriceInAgentCurrency(
      (sum + sumAtPickup) / rentalDays,
      offer.currency
    )
  };
};

export const formConfigToSearch = async (
  data: FormConfigData,
  agentId: string,
  customProxy = ''
): Promise<SearchData> => ({
  agentId,
  location: {
    pickUp: await getLocationById(
      data.request.pickupLocation,
      data.request.locale,
      undefined,
      customProxy
    ).catch(() => null),
    dropOff: await getLocationById(
      data.request.dropoffLocation,
      data.request.locale,
      undefined,
      customProxy
    ).catch(() => null)
  },
  dates: {
    from: moment(data.request.pickupDateTime),
    to: moment(data.request.dropoffDateTime)
  },
  driverAge: data.request.driverAge,
  driverCountry: data.request.driverCountry,
  locale: data.request.locale,
  rentDays: dayDiff(
    moment(data.request.pickupDateTime).hour(0).minute(0),
    moment(data.request.dropoffDateTime).hour(0).minute(0)
  )
});

export const prepareOptions = (values) => {
  const options = [];

  values.forEach((option) => {
    const keys = Object.keys(option);
    options.push({
      label: option[keys[0]],
      value: keys[0],
      bold: option?.bold === true
    });
  });
  return options;
};
